import { Role } from '~madrasa/core/enums';
import { DefaultProperties } from '../default.model';
import { CorePersonModel } from './person.model';

export interface CorePersonRelationshipModel extends DefaultProperties {
  // Columns
  id?: number;
  person_id?: number;
  person_in_relationship_id?: number;
  relationship?: string;

  // Relations
  person?: CorePersonModel;
  person_in_relationship?: CorePersonModel;
}

export class CorePersonRelationshipPermission {
  static readonly CREATE = [Role.LESSOR, Role.MANAGER, Role.ORGANISATOR, Role.DIRECTOR];
  static readonly READ = [Role.LESSOR, Role.MANAGER, Role.ORGANISATOR, Role.DIRECTOR, Role.TEACHER];
  static readonly UPDATE = [Role.LESSOR, Role.MANAGER, Role.ORGANISATOR, Role.DIRECTOR];
  static readonly DELETE = [Role.LESSOR];
  static readonly SOFT_DELETE = [
    ...CorePersonRelationshipPermission.DELETE,
    Role.MANAGER,
    Role.ORGANISATOR,
    Role.DIRECTOR
  ];
}
