import { Role } from '~madrasa/core/enums';
import { CorePersonDataModel, CorePersonModel } from '../core';
import { DefaultProperties } from '../default.model';
import { AccountingCashBookEntryModel } from './cash-book-entry.model';
import { AccountingEntryModel } from './entry.model';

export interface AccountingStudentBalanceModel extends DefaultProperties {
  // Columns
  id?: number;
  person_id?: number;
  amount?: number;
  month_of_balance?: string | Date;
  cash_book_entry_id?: number;
  entry_id?: number;
  description?: string;

  // Relations
  person?: CorePersonModel;
  current_person_data?: CorePersonDataModel;
  cash_book_entry?: AccountingCashBookEntryModel;
  entry?: AccountingEntryModel;
}

export class AccountingStudentBalancePermission {
  static readonly CREATE = [Role.LESSOR, Role.MANAGER];
  static readonly READ = [Role.LESSOR, Role.MANAGER, Role.DIRECTOR];
  static readonly UPDATE = [Role.LESSOR, Role.MANAGER];
  static readonly DELETE = [Role.LESSOR];
  static readonly SOFT_DELETE = [...AccountingStudentBalancePermission.DELETE, Role.MANAGER];
}
