import { Role } from '~madrasa/core/enums';
import { DefaultProperties } from '../default.model';
import { StaffStudentModel } from '../staff';
import { CorePersonDataModel, CorePersonModel } from '../core';
import { AcademyCourseModel } from './course.model';
import { AcademyCourseStudentStatusEnum } from './course-student-status.enum';

export interface AcademyCourseStudentModel extends DefaultProperties {
  // Columns
  id?: number;
  course_id?: number;
  person_id?: number;
  status?: AcademyCourseStudentStatusEnum;
  course_price?: number;

  // Computed
  price?: number;

  // Relations
  course?: AcademyCourseModel;
  latest_student?: StaffStudentModel;
  person?: CorePersonModel;
  current_person_data?: CorePersonDataModel;
}

export class AcademyCourseStudentPermission {
  static readonly CREATE = [Role.LESSOR, Role.MANAGER, Role.ORGANISATOR, Role.DIRECTOR];
  static readonly READ = [Role.LESSOR, Role.MANAGER, Role.ORGANISATOR, Role.DIRECTOR, Role.TEACHER];
  static readonly UPDATE = [Role.LESSOR, Role.MANAGER, Role.ORGANISATOR, Role.DIRECTOR];
  static readonly DELETE = [Role.LESSOR];
  static readonly SOFT_DELETE = [...AcademyCourseStudentPermission.DELETE];
}
