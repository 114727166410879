import { Role } from '~madrasa/core/enums';
import { DefaultProperties } from '../default.model';
import { AccessUserModel } from '../access';
import {
  AcademyCourseStudentModel,
  AcademyCourseTeacherModel,
  AcademyOrganisationOrganisatorModel,
  AcademyOrganisationPersonModel,
  AcademyOrganisationTeacherSalaryModel,
  AcademySchoolDirectorModel,
  AcademySchoolTeacherModel,
  AcademySchoolTeacherSalaryModel
} from '../academy';
import {
  StaffDirectorModel,
  StaffManagerModel,
  StaffMemberModel,
  StaffOrganisatorModel,
  StaffParentModel,
  StaffStudentModel,
  StaffTeacherModel
} from '../staff';
import { AcademySchoolParentModel } from '../academy/school-parent.model';
import { AcademySchoolStudentModel } from '../academy/school-student.model';
import { FilePersonMediaModel } from '../file';
import { AccountingStudentBalanceModel } from '../accounting';
import { CorePersonModel } from './person.model';
import { CoreGenderEnum } from './gender.enum';
import { CoreMaritalStatusEnum } from './marital-status.enum';
import { CorePersonRelationshipModel } from './person-relationship.model';
import { AcademySchoolMemberModel } from '../academy/school-member.model';

export interface CorePersonDataModel extends DefaultProperties {
  // Columns
  id?: number;
  person_id?: number;
  first_name?: string;
  last_name?: string;
  extra_name?: string;
  date_of_birth?: Date | string;
  place_of_birth?: string;
  citizenship?: string;
  profession?: string;
  gender?: CoreGenderEnum;
  insurance_number?: string;
  zmr_number?: number;
  iban?: string;
  bic?: string;
  account_owner?: string;
  academic_degree_prefix?: any;
  academic_degree_suffix?: any;
  address?: any;
  email_address?: any;
  phone_number?: any;
  marital_status?: CoreMaritalStatusEnum;

  // Relations
  person?: CorePersonModel;
  user?: AccessUserModel;
  latest_student?: StaffStudentModel;
  latest_member?: StaffMemberModel;
  latest_parent?: StaffParentModel;
  latest_teacher?: StaffTeacherModel;
  latest_director?: StaffDirectorModel;
  latest_organisator?: StaffOrganisatorModel;
  latest_manager?: StaffManagerModel;

  members?: StaffParentModel[];
  school_members_active?: AcademySchoolMemberModel[];

  parents?: StaffParentModel[];
  school_parents_active?: AcademySchoolParentModel[];

  students?: StaffStudentModel[];
  school_students_active?: AcademySchoolStudentModel[];

  teachers?: StaffTeacherModel[];
  school_teachers_active?: AcademySchoolTeacherModel[];

  directors?: StaffDirectorModel[];
  school_directors_active?: AcademySchoolDirectorModel[];

  organisators?: StaffOrganisatorModel[];
  organisation_organisators_active?: AcademyOrganisationOrganisatorModel[];

  organisation_persons_active?: AcademyOrganisationPersonModel[];

  managers?: StaffManagerModel[];

  side_a_person_relationships?: CorePersonRelationshipModel[];
  side_b_person_relationships?: CorePersonRelationshipModel[];

  course_students?: AcademyCourseStudentModel[];
  course_teachers?: AcademyCourseTeacherModel[];

  organisation_teacher_salaries?: AcademyOrganisationTeacherSalaryModel[];
  school_teacher_salaries?: AcademySchoolTeacherSalaryModel[];

  student_balances?: AccountingStudentBalanceModel[];

  person_media?: FilePersonMediaModel[];
}

export class CorePersonDataPermission {
  static readonly CREATE = [Role.LESSOR, Role.MANAGER, Role.ORGANISATOR, Role.DIRECTOR];
  static readonly READ = [Role.LESSOR, Role.MANAGER, Role.ORGANISATOR, Role.DIRECTOR];
  static readonly UPDATE = [Role.LESSOR, Role.MANAGER, Role.ORGANISATOR, Role.DIRECTOR];
  static readonly DELETE = [Role.LESSOR];
  static readonly SOFT_DELETE = [...CorePersonDataPermission.DELETE];
}
