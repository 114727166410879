import { Role } from '~madrasa/core/enums';
import { DefaultProperties } from '../default.model';
import { CorePersonDataModel, CorePersonModel } from '../core';
import { StaffTeacherModel } from '../staff';
import { AcademyUnitModel } from './unit.model';

export interface AcademyUnitTeacherModel extends DefaultProperties {
  // Columns
  id?: number;
  unit_id?: number;
  person_id?: number;
  hourly_rate?: number;

  // Relations
  unit?: AcademyUnitModel;
  latest_teacher?: StaffTeacherModel;
  person?: CorePersonModel;
  current_person_data?: CorePersonDataModel;
}

export class AcademyUnitTeacherPermission {
  static readonly CREATE = [Role.LESSOR, Role.MANAGER, Role.ORGANISATOR, Role.DIRECTOR];
  static readonly READ = [Role.LESSOR, Role.MANAGER, Role.ORGANISATOR, Role.DIRECTOR, Role.TEACHER];
  static readonly UPDATE = [Role.LESSOR, Role.MANAGER, Role.ORGANISATOR, Role.DIRECTOR];
  static readonly DELETE = [Role.LESSOR];
  static readonly SOFT_DELETE = [...AcademyUnitTeacherPermission.DELETE, Role.MANAGER];
}
