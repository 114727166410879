import { DefaultProperties } from '../default.model';
import { FileDocumentOrganisationModel } from './document_organisation.model';
import { FileDocumentRoleModel } from './document_role.model';
import { FileDocumentSchoolModel } from './document_school.model';
import { FileModel } from './file.model';
import { Role } from '~madrasa/core/enums';

export interface FileDocumentModel extends DefaultProperties {
  // Columns
  id?: number;
  file_id?: string;
  description?: string;
  name?: string;
  document_id?: number;

  // Computed
  file_name?: string;
  file_size?: number;
  file_mime_type?: string;

  // Relations
  file?: FileModel;
  folder?: FileDocumentModel;

  documents?: FileDocumentModel[];
  document_organisations?: FileDocumentOrganisationModel[];
  document_schools?: FileDocumentSchoolModel[];
  document_roles?: FileDocumentRoleModel[];
}

export class FileDocumentModelPermission {
  static readonly CREATE = [Role.LESSOR, Role.MANAGER, Role.ORGANISATOR, Role.DIRECTOR];
  static readonly READ = [Role.LESSOR, Role.MANAGER, Role.ORGANISATOR, Role.DIRECTOR, Role.TEACHER];
  static readonly UPDATE = [Role.LESSOR, Role.MANAGER, Role.ORGANISATOR, Role.DIRECTOR];
  static readonly DELETE = [Role.LESSOR, Role.MANAGER, Role.ORGANISATOR, Role.DIRECTOR];
  static readonly SOFT_DELETE = [...FileDocumentModelPermission.DELETE, Role.MANAGER];
}
