import {
  AcademySchoolMemberModel,
  CorePersonDataModel,
  CorePersonModel,
  DefaultProperties
} from '../../models';
import { Role } from '~madrasa/core/enums';
import { StaffStatusModel } from './staff-status.model';
import { StaffPriceCategoryModel } from './price-category.model';

export interface StaffMemberModel extends DefaultProperties {
  // Columns
  id?: number;
  person_id?: number;
  status_id?: number;
  registration_date?: Date | string;
  debit_date?: string;
  price_category_id?: number;
  price?: number;
  balance?: number;

  // Relations
  person?: CorePersonModel;
  current_person_data?: CorePersonDataModel;
  status?: StaffStatusModel;
  price_category?: StaffPriceCategoryModel;

  school_members_active?: AcademySchoolMemberModel[];
}

export class StaffMemberPermission {
  static readonly CREATE = [Role.LESSOR];
  static readonly READ = [Role.LESSOR];
  static readonly UPDATE = [Role.LESSOR];
  static readonly DELETE = [Role.LESSOR];
  static readonly SOFT_DELETE = [...StaffMemberPermission.DELETE];
}
