import { Role } from '~madrasa/core/enums';
import { DefaultProperties } from '../default.model';
import { AcademyOrganisationModel } from './organisation.model';
import { AcademySchoolModel } from './school.model';

export interface AcademyTeacherHourlyRateModel extends DefaultProperties {
  // Columns
  id?: number;
  name?: string;
  description?: string;
  columns?: string[];
  rows?: number[][];

  // Relations
  organisations?: AcademyOrganisationModel[];
  schools?: AcademySchoolModel[];
}

export class AcademyTeacherHourlyRatePermission {
  static readonly CREATE = [Role.LESSOR, Role.MANAGER];
  static readonly READ = [Role.LESSOR, Role.MANAGER];
  static readonly UPDATE = [Role.LESSOR, Role.MANAGER];
  static readonly DELETE = [Role.LESSOR];
  static readonly SOFT_DELETE = [...AcademyTeacherHourlyRatePermission.DELETE, Role.MANAGER];
}
