import { DefaultProperties } from '../../models';
import { Role } from '~madrasa/core/enums';

export interface StaffFunctionModel extends DefaultProperties {
  // Columns
  id?: number;
  name?: string;
  description?: string;
  index?: number;
  minimum?: number;
  maximum?: number;
  duration?: number;
}

export class StaffFunctionPermission {
  static readonly CREATE = [Role.LESSOR];
  static readonly READ = [Role.LESSOR];
  static readonly UPDATE = [Role.LESSOR];
  static readonly DELETE = [Role.LESSOR];
  static readonly SOFT_DELETE = [...StaffFunctionPermission.DELETE];
}
