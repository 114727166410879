import { DefaultProperties } from '../default.model';
import { CorePersonDataModel, CorePersonModel } from '../core';
import {
  AcademyClassBookEntryTeacherModel,
  AcademyCourseTeacherModel,
  AcademySchoolTeacherModel,
  AcademyTeacherHourlyRateModel,
  AcademyUnitTeacherModel
} from '../academy';
import { StaffStatusModel } from './staff-status.model';
import { StaffEducationPathModel } from './education-path.model';
import { Role } from '~madrasa/core/enums';

export interface StaffTeacherModel extends DefaultProperties {
  // Columns
  id?: number;
  person_id?: number;
  status_id?: number;
  start_of_work?: Date | string;
  monthly_rate?: number;
  hourly_rate?: number;
  teacher_hourly_rate_id?: number;
  initial_worked_months?: number;
  hourly_rate_value?: number;
  worked_months?: number;

  // Relations
  person?: CorePersonModel;
  current_person_data?: CorePersonDataModel;
  teacher_hourly_rate?: AcademyTeacherHourlyRateModel;
  status?: StaffStatusModel;

  class_book_entry_teachers?: AcademyClassBookEntryTeacherModel[];
  course_teachers?: AcademyCourseTeacherModel[];
  school_teachers_active?: AcademySchoolTeacherModel[];
  unit_teachers?: AcademyUnitTeacherModel[];
  education_paths?: StaffEducationPathModel[];
}

export class StaffTeacherPermission {
  static readonly CREATE = [Role.LESSOR, Role.MANAGER, Role.ORGANISATOR, Role.DIRECTOR];
  static readonly READ = [Role.LESSOR, Role.MANAGER, Role.ORGANISATOR, Role.DIRECTOR];
  static readonly UPDATE = [Role.LESSOR, Role.MANAGER, Role.ORGANISATOR, Role.DIRECTOR];
  static readonly DELETE = [Role.LESSOR];
  static readonly SOFT_DELETE = [...StaffTeacherPermission.DELETE, Role.MANAGER];
}
