import { Role } from '~madrasa/core/enums';
import { DefaultProperties } from '../default.model';

export interface CorePersonModel extends DefaultProperties {
  // Columns
  id?: number;
}

export class CorePersonPermission {
  static readonly CREATE = [Role.LESSOR, Role.MANAGER, Role.ORGANISATOR, Role.DIRECTOR];
  static readonly READ = [Role.LESSOR, Role.MANAGER, Role.ORGANISATOR, Role.DIRECTOR];
  static readonly UPDATE = [Role.LESSOR, Role.MANAGER, Role.ORGANISATOR, Role.DIRECTOR];
  static readonly DELETE = [Role.LESSOR];
  static readonly SOFT_DELETE = [...CorePersonPermission.DELETE, Role.MANAGER];
}
