import { Role } from '~madrasa/core/enums';
import { DefaultProperties } from '../default.model';
import { CorePersonDataModel, CorePersonModel } from '../core';
import {
  AcademyClassBookEntryStudentModel,
  AcademyCourseStudentModel,
  AcademySchoolStudentModel
} from '../academy';
import { StaffStatusModel } from './staff-status.model';

export interface StaffStudentModel extends DefaultProperties {
  // Columns
  id?: number;
  person_id?: number;
  has_photo_publish_approval?: boolean;
  status_id?: number;
  registration_date?: Date | string;
  debit_date?: string;
  balance?: number;

  // Relations
  person?: CorePersonModel;
  current_person_data?: CorePersonDataModel;
  status?: StaffStatusModel;

  class_book_entry_students?: AcademyClassBookEntryStudentModel[];
  course_students?: AcademyCourseStudentModel[];
  school_students_active?: AcademySchoolStudentModel[];
  latest_course_students?: AcademyCourseStudentModel[];
  latest_course_students_active?: AcademyCourseStudentModel[];
}

export class StaffStudentPermission {
  static readonly CREATE = [Role.LESSOR, Role.MANAGER, Role.ORGANISATOR, Role.DIRECTOR];
  static readonly READ = [Role.LESSOR, Role.MANAGER, Role.ORGANISATOR, Role.DIRECTOR];
  static readonly UPDATE = [Role.LESSOR, Role.MANAGER, Role.ORGANISATOR, Role.DIRECTOR];
  static readonly DELETE = [Role.LESSOR];
  static readonly SOFT_DELETE = [...StaffStudentPermission.DELETE, Role.MANAGER];
}
