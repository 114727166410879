import {
  AcademyOrganisationOrganisatorModel,
  CorePersonDataModel,
  CorePersonModel,
  DefaultProperties
} from '../../models';
import { Role } from '~madrasa/core/enums';
import { StaffStatusModel } from './staff-status.model';

export interface StaffOrganisatorModel extends DefaultProperties {
  // Columns
  id?: number;
  person_id?: number;
  status_id?: number;

  // Relations
  person?: CorePersonModel;
  current_person_data?: CorePersonDataModel;
  status?: StaffStatusModel;

  organisation_organisators_active?: AcademyOrganisationOrganisatorModel[];
}

export class StaffOrganisatorPermission {
  static readonly CREATE = [Role.LESSOR, Role.MANAGER];
  static readonly READ = [Role.LESSOR, Role.MANAGER];
  static readonly UPDATE = [Role.LESSOR, Role.MANAGER];
  static readonly DELETE = [Role.LESSOR];
  static readonly SOFT_DELETE = [...StaffOrganisatorPermission.DELETE, Role.MANAGER];
}
