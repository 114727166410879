import {
  AcademyOrganisationModel,
  AcademyTeacherHourlyRateModel,
  CorePersonDataModel,
  CorePersonModel,
  DefaultProperties,
  StaffTeacherModel
} from '../../models';
import { Role } from '~madrasa/core/enums';

export interface AcademyOrganisationTeacherSalaryModel extends DefaultProperties {
  // Columns
  id?: number;
  organisation_id?: number;
  person_id?: number;
  hourly_rate?: number;
  monthly_rate?: number;
  teacher_hourly_rate_id?: number;

  // Relations
  organisation?: AcademyOrganisationModel;
  person?: CorePersonModel;
  current_person_data?: CorePersonDataModel;
  teacher_hourly_rate?: AcademyTeacherHourlyRateModel;
  latest_teacher?: StaffTeacherModel;
}

export class AcademyOrganisationTeacherSalaryPermission {
  static readonly CREATE = [Role.LESSOR, Role.MANAGER, Role.ORGANISATOR];
  static readonly READ = [Role.LESSOR, Role.MANAGER, Role.ORGANISATOR, Role.DIRECTOR, Role.TEACHER];
  static readonly UPDATE = [Role.LESSOR, Role.MANAGER, Role.ORGANISATOR];
  static readonly DELETE = [Role.LESSOR];
  static readonly SOFT_DELETE = [
    ...AcademyOrganisationTeacherSalaryPermission.DELETE,
    Role.MANAGER,
    Role.ORGANISATOR
  ];
}
