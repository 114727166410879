import { Role } from '~madrasa/core/enums';
import { DefaultProperties } from '../default.model';
import { AcademyCourseModel } from './course.model';

export interface AcademySchoolPeriodModel extends DefaultProperties {
  // Columns
  id?: number;
  starts_at?: Date | string;
  first_semester_ends_at?: Date | string;
  ends_at?: Date | string;
  is_active?: boolean;

  // Computed
  name?: string;

  // Relations
  courses?: AcademyCourseModel[];
}

export class AcademySchoolPeriodPermission {
  static readonly CREATE = [Role.LESSOR, Role.MANAGER];
  static readonly READ = [Role.LESSOR, Role.MANAGER];
  static readonly UPDATE = [Role.LESSOR, Role.MANAGER];
  static readonly DELETE = [Role.LESSOR];
  static readonly SOFT_DELETE = [...AcademySchoolPeriodPermission.DELETE, Role.MANAGER];
}
