import { Role } from '~madrasa/core/enums';
import { DefaultProperties } from '../default.model';
import { StaffEducationTypeModel } from './education-type.model';

export interface StaffEducationPathModel extends DefaultProperties {
  // Columns
  id?: number;
  person_id?: number;
  education_type_id?: number;
  name_of_education?: string;
  training_facility?: string;
  place_of_education?: string;
  starts_at?: Date | string;
  ends_at?: Date | string;

  // Relations
  education_type?: StaffEducationTypeModel;
}

export class StaffEducationPathPermission {
  static readonly CREATE = [Role.LESSOR, Role.MANAGER];
  static readonly READ = [Role.LESSOR, Role.MANAGER];
  static readonly UPDATE = [Role.LESSOR, Role.MANAGER];
  static readonly DELETE = [Role.LESSOR];
  static readonly SOFT_DELETE = [...StaffEducationPathPermission.DELETE, Role.MANAGER];
}
