import { Role } from '~madrasa/core/enums';
import { CorePersonDataModel, CorePersonModel } from '../core';
import { DefaultProperties } from '../default.model';
import { AcademyCertificateSubjectModel } from './certificate-subject.model';
import { AcademyCertificateTypeEnum } from './certificate-type.enum';
import { AcademyCourseModel } from './course.model';
import { AcademyOrganisationModel } from './organisation.model';
import { AcademySchoolModel } from './school.model';

export interface AcademyCertificateModel extends DefaultProperties {
  // Columns
  id?: number;
  person_id?: number;
  certificate_type?: AcademyCertificateTypeEnum;
  course_id?: number;
  school_id?: number;
  organisation_id?: number;
  school_period_id?: number;
  options?: any;
  description?: string;
  date_of_certificate?: Date | string;

  // Relations
  current_person_data?: CorePersonDataModel;
  person?: CorePersonModel;
  course?: AcademyCourseModel;
  school?: AcademySchoolModel;
  organisation?: AcademyOrganisationModel;
  certificate_subjects?: AcademyCertificateSubjectModel[];
}

export class AcademyCertificatePermission {
  static readonly CREATE = [Role.LESSOR, Role.MANAGER, Role.ORGANISATOR, Role.DIRECTOR];
  static readonly READ = [Role.LESSOR, Role.MANAGER, Role.ORGANISATOR, Role.DIRECTOR, Role.TEACHER];
  static readonly UPDATE = [
    Role.LESSOR,
    Role.MANAGER,
    Role.ORGANISATOR,
    Role.DIRECTOR,
    Role.TEACHER
  ];
  static readonly DELETE = [Role.LESSOR];
  static readonly SOFT_DELETE = [
    ...AcademyCertificatePermission.DELETE,
    Role.MANAGER,
    Role.ORGANISATOR,
    Role.DIRECTOR
  ];
}
