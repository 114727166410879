import { CorePersonDataModel, CorePersonModel, DefaultProperties } from '../../models';
import { Role } from '~madrasa/core/enums';
import { StaffStatusModel } from './staff-status.model';

export interface StaffManagerModel extends DefaultProperties {
  // Columns
  id?: number;
  person_id?: number;
  status_id?: number;

  // Relations
  person?: CorePersonModel;
  current_person_data?: CorePersonDataModel;
  status?: StaffStatusModel;
}

export class StaffManagerPermission {
  static readonly CREATE = [Role.LESSOR];
  static readonly READ = [Role.LESSOR];
  static readonly UPDATE = [Role.LESSOR];
  static readonly DELETE = [Role.LESSOR];
  static readonly SOFT_DELETE = [...StaffManagerPermission.DELETE];
}
