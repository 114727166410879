import { DefaultProperties } from '../../models';
import { Role } from '~madrasa/core/enums';

export interface StaffPriceCategoryModel extends DefaultProperties {
  // Columns
  id?: number;
  name?: string;
  description?: string;
  value?: number;
  year?: number;

  // Computed
  full_name?: string;
}

export class StaffPriceCategoryPermission {
  static readonly CREATE = [Role.LESSOR];
  static readonly READ = [Role.LESSOR];
  static readonly UPDATE = [Role.LESSOR];
  static readonly DELETE = [Role.LESSOR];
  static readonly SOFT_DELETE = [...StaffPriceCategoryPermission.DELETE];
}
