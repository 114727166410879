import { Role } from '~madrasa/core/enums';
import { DefaultProperties } from '../default.model';
import { AcademyOrganisationModel } from './organisation.model';
import { AcademyCourseModel } from './course.model';
import { AcademyCoursePriceModel } from './course-price.model';

export interface AcademyCourseTypeModel extends DefaultProperties {
  // Columns
  id?: number;
  organisation_id?: number;
  name?: string;
  shortcut?: string;
  description?: string;
  is_active?: boolean;

  // Relations
  organisation?: AcademyOrganisationModel;
  course_type_course_price?: AcademyCoursePriceModel;

  courses?: AcademyCourseModel[];
}

export class AcademyCourseTypePermission {
  static readonly CREATE = [Role.LESSOR, Role.MANAGER];
  static readonly READ = [Role.LESSOR, Role.MANAGER];
  static readonly UPDATE = [Role.LESSOR, Role.MANAGER];
  static readonly DELETE = [Role.LESSOR];
  static readonly SOFT_DELETE = [...AcademyCourseTypePermission.DELETE, Role.MANAGER];
}
