import { Role } from '~madrasa/core/enums';
import { DefaultProperties } from '~madrasa/models';

export interface StaffStatusModel extends DefaultProperties {
  // Columns
  id?: number;
  name?: string;
  text_color?: string;
  background_color?: string;
  description?: string;
  is_active?: boolean;

  // Relations
}

export class StaffStatusPermission {
  static readonly CREATE = [Role.LESSOR, Role.MANAGER];
  static readonly READ = [Role.LESSOR, Role.MANAGER, Role.ORGANISATOR, Role.DIRECTOR];
  static readonly UPDATE = [Role.LESSOR, Role.MANAGER];
  static readonly DELETE = [Role.LESSOR];
  static readonly SOFT_DELETE = [...StaffStatusPermission.DELETE, Role.MANAGER];
}
