import { CorePersonDataModel, CorePersonModel } from '../core';
import { DefaultProperties } from '../default.model';
import { Role } from '~madrasa/core/enums';
import { AccountingEntryModel } from './entry.model';

export interface AccountingTeacherWageModel extends DefaultProperties {
  // Columns
  id?: number;
  person_id?: number;
  date?: Date | string;
  wage?: number;
  wage_detail?: {
    wage?: number;
    organisations?: {
      [key: number]: {
        name?: string;
        wage?: number;
        schools?: {
          [key: number]: {
            name?: string;
            wage?: number;
            class_book_entries?: {
              id?: number;
              type?: string;
              hourly_rate?: number;
            }[];
          };
        };
      };
    };
  };
  description?: string;
  entry_id?: number;

  // Relations
  person?: CorePersonModel;
  current_person_data?: CorePersonDataModel;
  entry?: AccountingEntryModel;
}

export class AccountingTeacherWagePermission {
  static readonly CREATE = [Role.LESSOR, Role.MANAGER];
  static readonly READ = [Role.LESSOR, Role.MANAGER, Role.DIRECTOR];
  static readonly UPDATE = [Role.LESSOR, Role.MANAGER];
  static readonly DELETE = [Role.LESSOR];
  static readonly SOFT_DELETE = [...AccountingTeacherWagePermission.DELETE, Role.MANAGER];
}
