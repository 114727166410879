import { Role } from '~madrasa/core/enums';
import { Timestamp } from '~ngx-shared/models';

export interface CoreTenantModel extends Timestamp {
  // Columns
  id?: number;
  name?: string;
  slug?: string;
  primary_color?: string;
  secondary_color?: string;
  logo_url?: string;
  favicon_url?: string;
  is_active?: boolean;
  are_course_names_forced?: boolean;
  organisations_allowed_count?: number;
  schools_allowed_count?: number;
  can_director_crud_teacher?: boolean;
  can_director_crud_course_price?: boolean;
  can_organisator_crud_course_price?: boolean;
  can_organisator_crud_teacher?: boolean;
  can_organisator_crud_director?: boolean;
  custom?: any;
}

export class CoreTenantPermission {
  static readonly CREATE = [Role.LESSOR];
  static readonly READ = [Role.LESSOR];
  static readonly UPDATE = [Role.LESSOR];
  static readonly DELETE = [Role.LESSOR];
  static readonly SOFT_DELETE = [...CoreTenantPermission.DELETE];
}
