import { Role } from '~madrasa/core/enums';
import { DefaultProperties } from '../default.model';
import { StaffTeacherModel } from '../staff';
import { CorePersonDataModel, CorePersonModel } from '../core';
import { AcademyCourseModel } from './course.model';
import { AcademyTeacherRoleEnum } from './teacher-role.enum';

export interface AcademyCourseTeacherModel extends DefaultProperties {
  // Columns
  id?: number;
  course_id?: number;
  person_id?: number;
  teaching_role?: AcademyTeacherRoleEnum;
  hourly_rate?: number;

  // Relations
  course?: AcademyCourseModel;
  latest_teacher?: StaffTeacherModel;
  person?: CorePersonModel;
  current_person_data?: CorePersonDataModel;
}

export class AcademyCourseTeacherPermission {
  static readonly CREATE = [Role.LESSOR, Role.MANAGER, Role.ORGANISATOR, Role.DIRECTOR];
  static readonly READ = [Role.LESSOR, Role.MANAGER, Role.ORGANISATOR, Role.DIRECTOR, Role.TEACHER];
  static readonly UPDATE = [Role.LESSOR, Role.MANAGER, Role.ORGANISATOR, Role.DIRECTOR];
  static readonly DELETE = [Role.LESSOR];
  static readonly SOFT_DELETE = [...AcademyCourseTeacherPermission.DELETE];
}
