import { Role } from '~madrasa/core/enums';
import { AcademySchoolModel } from '../academy';
import { DefaultProperties } from '../default.model';
import { FileReceiptDocumentModel } from '../file';
import { AccountingCashBookEntryCategoryModel } from './cash-book-entry-category.model';
import { AccountingCashBookEntryTypeEnum } from './cash-book-entry-type.enum';
import { AccountingEntryModel } from './entry.model';
import { AccountingPeriodModel } from './period.model';
import { AccountingStudentBalanceModel } from './student-balance.model';

export interface AccountingCashBookEntryModel extends DefaultProperties {
  // Columns
  id?: number;
  number?: number;
  date_of_entry?: string | Date;
  accounting_period_id?: number;
  school_id?: number;
  cash_book_entry_category_id?: number;
  type?: AccountingCashBookEntryTypeEnum;
  amount?: number;
  description?: string;
  payed_by?: string;

  // Relations
  accounting_period?: AccountingPeriodModel;
  cash_book_entry_category?: AccountingCashBookEntryCategoryModel;
  entry?: AccountingEntryModel;
  school?: AcademySchoolModel;
  student_balance?: AccountingStudentBalanceModel;

  receipt_documents?: FileReceiptDocumentModel[];
  student_balances?: AccountingStudentBalanceModel[];
}

export class AccountingCashBookEntryPermission {
  static readonly CREATE = [Role.LESSOR, Role.MANAGER, Role.DIRECTOR];
  static readonly READ = [Role.LESSOR, Role.MANAGER, Role.DIRECTOR];
  static readonly UPDATE = [Role.LESSOR, Role.MANAGER];
  static readonly DELETE = [Role.LESSOR];
  static readonly SOFT_DELETE = [...AccountingCashBookEntryPermission.DELETE, Role.MANAGER];
}
