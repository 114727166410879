import { Role } from '~madrasa/core/enums';
import { DefaultProperties } from '../default.model';
import { CorePersonDataModel, CorePersonModel } from '../core';
import { AcademySchoolDirectorModel } from '../academy';
import { StaffStatusModel } from './staff-status.model';

export interface StaffDirectorModel extends DefaultProperties {
  // Columns
  id?: number;
  person_id?: number;
  status_id?: number;
  start_of_work?: Date | string;
  monthly_rate?: number;
  hourly_rate?: number;
  is_responsible?: boolean;

  // Relations
  person?: CorePersonModel;
  status?: StaffStatusModel;
  current_person_data?: CorePersonDataModel;

  school_directors_active?: AcademySchoolDirectorModel[];
}

export class StaffDirectorPermission {
  static readonly CREATE = [Role.LESSOR, Role.MANAGER, Role.ORGANISATOR];
  static readonly READ = [Role.LESSOR, Role.MANAGER, Role.ORGANISATOR];
  static readonly UPDATE = [Role.LESSOR, Role.MANAGER, Role.ORGANISATOR];
  static readonly DELETE = [Role.LESSOR];
  static readonly SOFT_DELETE = [...StaffDirectorPermission.DELETE, Role.MANAGER];
}
